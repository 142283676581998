import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Logo from "../../Images/sblogopng.png";
import Studentcopy from "../../Images/stdc.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    borderWidth: 1,
  },
  section: {
    margin: 1,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    // borderWidth: 1,
    // borderBottom: 0.5,
    borderColor: "grey",
    paddingBottom: 0,
    // borderTop: 1,
  },
  viewer: {
    width: "100%", // Use percentage to ensure responsiveness
    height: "100vh", // Use viewport height for full height
  },
  image: {
    width: 100,
    height: 90,
  },
  section2: {
    marginTop: 10,
    padding: 10,
    fontSize: 12,
  },
  section21: {
    borderWidth: 0.5,
    width: "100%",
    // height: 100,
    // height: "90%",
    borderRadius: 8,
    overflow: "hidden",
  },
  tablerow: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
  },
  tablecoltitle: {
    width: "50%",
    // backgroundColor: "#D2E0FB",
    backgroundColor: "#F1F1F1",

    padding: 5,
    borderWidth: 0.5,
  },
  tablecolval: {
    width: "50%",
    padding: 5,
    borderWidth: 0.5,
  },
  tablerow2: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
  },
  tablecoltitle2: {
    width: "75%",
    // backgroundColor: "#D2E0FB",
    padding: 5,
    // borderWidth: 0.5,
  },
  tablecolval2: {
    width: "30%",
    padding: 5,
    // borderWidth: 0.5,
  },
});

// Create Document Component
function Payslippdf() {
  const { data } = useParams();
  const [feedata, setfeeData] = useState({});
  let dollarIndianLocale = Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
  });

  useEffect(() => {
    try {
      console.log(feedata);
    } catch (error) {
      console.error("Error parsing JSON data:", error);
    }
  }, []);
  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document */}
      <Document>
        {/* Render a single page */}
        <Page size="A4" style={styles.page}>
          <View>
            <View style={styles.section}>
              <Image style={styles.image} src={Logo} />
            </View>
          </View>
          <View style={styles.section2}>
            <View style={styles.section21}>
              <View
                style={{
                  width: "100%",
                  padding: 10,
                  backgroundColor: "#8EACCD",
                }}
              >
                <Text style={{ fontSize: 12, color: "white" }}>
                  BREEZE GROUP OF COMPANIES
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  padding: 10,
                  display: "flex",
                  textAlign: "center",
                  borderBottomWidth: 0.2,
                }}
              >
                <Text style={{ fontSize: 12, color: "black" }}>
                  Pay Slip For November 2024
                </Text>
              </View>
              {/* ------------------header complete--------------------- */}
              <View
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <View style={{ width: "50%", borderRightWidth: 0.5 }}>
                  <View style={styles.tablerow}>
                    <View style={styles.tablecoltitle}>
                      <Text>ID</Text>{" "}
                    </View>
                    <View style={styles.tablecolval}>
                      <Text>SB00021</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow}>
                    <View style={styles.tablecoltitle}>
                      <Text>Bank</Text>{" "}
                    </View>
                    <View style={styles.tablecolval}>
                      <Text>SBI</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow}>
                    <View style={styles.tablecoltitle}>
                      <Text>DOJ</Text>{" "}
                    </View>
                    <View style={styles.tablecolval}>
                      <Text>14-09-2023</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow}>
                    <View style={styles.tablecoltitle}>
                      <Text>PF No.</Text>{" "}
                    </View>
                    <View style={styles.tablecolval}>
                      <Text>120</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow}>
                    <View style={styles.tablecoltitle}>
                      <Text>Location</Text>{" "}
                    </View>
                    <View style={styles.tablecolval}>
                      <Text>Chennai</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow}>
                    <View style={styles.tablecoltitle}>
                      <Text>Department</Text>{" "}
                    </View>
                    <View style={styles.tablecolval}>
                      <Text>IT</Text>{" "}
                    </View>
                  </View>
                </View>
                <View style={{ width: "50%" }}>
                  <View style={styles.tablerow}>
                    <View style={styles.tablecoltitle}>
                      <Text>Name</Text>{" "}
                    </View>
                    <View style={styles.tablecolval}>
                      <Text>User</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow}>
                    <View style={styles.tablecoltitle}>
                      <Text>A/c No.</Text>{" "}
                    </View>
                    <View style={styles.tablecolval}>
                      <Text>012344556677</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow}>
                    <View style={styles.tablecoltitle}>
                      <Text>LOP Days</Text>{" "}
                    </View>
                    <View style={styles.tablecolval}>
                      <Text>0</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow}>
                    <View style={styles.tablecoltitle}>
                      <Text>STD Days</Text>{" "}
                    </View>
                    <View style={styles.tablecolval}>
                      <Text>30</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow}>
                    <View style={styles.tablecoltitle}>
                      <Text>Worked Days</Text>{" "}
                    </View>
                    <View style={styles.tablecolval}>
                      <Text>30</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow}>
                    <View style={styles.tablecoltitle}>
                      <Text>Designation</Text>{" "}
                    </View>
                    <View style={styles.tablecolval}>
                      <Text>Software Engineer</Text>{" "}
                    </View>
                  </View>
                </View>
              </View>
              {/*  */}
              <View
                style={{
                  //   height: 100,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  //   backgroundColor: "lightgreen",
                  marginTop: "20px",
                  borderWidth: 0.5,
                }}
              >
                <View style={{ width: "50%", borderRightWidth: 0.5 }}>
                  <View
                    style={[
                      styles.tablerow2,
                      { backgroundColor: "#D2E0FB", borderWidth: 0.5 },
                    ]}
                  >
                    <View style={styles.tablecoltitle2}>
                      <Text>Earnings </Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>Amount in Rs.</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow2}>
                    <View style={styles.tablecoltitle2}>
                      <Text>BASIC </Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>9,965.00</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow2}>
                    <View style={styles.tablecoltitle2}>
                      <Text>RENT ALLOWANCE</Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>4,982.00</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow2}>
                    <View style={styles.tablecoltitle2}>
                      <Text>SPECIAL ALLOWANCE</Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>7,093.00</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow2}>
                    <View style={styles.tablecoltitle2}>
                      <Text>CONVEYANCE</Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>800.00</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow2}>
                    <View style={styles.tablecoltitle2}>
                      <Text>ADDNL SPECIAL ALLOWANCE</Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>3,333.00</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow2}>
                    <View style={styles.tablecoltitle2}>
                      <Text>SHIFT ALLOWANCE</Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>1,8000.00</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow2}>
                    <View style={styles.tablecoltitle2}>
                      <Text>STAT BONUS</Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>700.00</Text>{" "}
                    </View>
                  </View>
                </View>
                {/* ------------- */}
                <View style={{ width: "50%" }}>
                  <View
                    style={[
                      styles.tablerow2,
                      { backgroundColor: "#D2E0FB", borderWidth: 0.5 },
                    ]}
                  >
                    <View style={styles.tablecoltitle2}>
                      <Text>Deductions </Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>Amount in Rs.</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow2}>
                    <View style={styles.tablecoltitle2}>
                      <Text>PROVIDENT FUND</Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>1,196.00</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow2}>
                    <View style={styles.tablecoltitle2}>
                      <Text>PROFESSIONAL TAX</Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>200.00</Text>{" "}
                    </View>
                  </View>
                  <View style={styles.tablerow2}>
                    <View style={styles.tablecoltitle2}>
                      <Text>INCOME TAX</Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>194.00</Text>{" "}
                    </View>
                  </View>
                </View>
              </View>
              {/*  */}
              <View
                style={{
                  //   height: 100,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#F1F1F1",

                  borderWidth: 0.5,
                }}
              >
                <View
                  style={{ width: "50%", borderRightWidth: 0.5, padding: 10 }}
                >
                  <View style={styles.tablerow2}>
                    <View style={styles.tablecoltitle2}>
                      <Text>GROSS EARNINGS </Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>28,673.00</Text>{" "}
                    </View>
                  </View>
                </View>
                {/* ------------- */}
                <View style={{ width: "50%", padding: 10 }}>
                  <View style={styles.tablerow2}>
                    <View style={styles.tablecoltitle2}>
                      <Text>TOTAL DEDUCTIONS</Text>{" "}
                    </View>
                    <View style={styles.tablecolval2}>
                      <Text>1,590.00</Text>{" "}
                    </View>
                  </View>
                </View>
              </View>
              {/*  */}
              <View
                style={{
                  //   height: 100,
                  //   backgroundColor: "#F1F1F1",
                  //   margin: 2,
                  marginTop: 15,
                  //   marginBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderWidth: 0.5,
                  borderRadius: 5,
                }}
              >
                <View
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ padding: 2 }}> TOTAL NET PAYABLE</Text>
                  <Text style={{ fontSize: 10, color: "grey", padding: 2 }}>
                    {" "}
                    Gross Earnings - Total Deductions
                  </Text>
                </View>
                <View
                  style={{
                    padding: 10,
                    backgroundColor: "#E0FBE2",
                    width: "25%",
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                    borderLeftWidth: 2,
                    borderLeftColor: "green",
                  }}
                >
                  <Text style={[styles.text, { fontSize: 15 }]}>
                    Rs.27,083/-{" "}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.section2,
              {
                display: "flex",
                width: "100%",
                justifyContent: "center",
                // backgroundColor: "red",
                textAlign: "center",
              },
            ]}
          >
            <Text style={{ fontSize: 10, color: "grey" }}>
              ~This is a Computer Generated Payslip and does not require any
              signature and stamp~
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default Payslippdf;
