import React, { useEffect } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { useParams, useNavigate } from "react-router-dom";
import Approvedr from "../../Components/Superusercomponents/Approvedr";
import Approveit from "../../Components/Superusercomponents/Approveit";
import Approvee from "../../Components/Superusercomponents/Approvee";
import Approvepc from "../../Components/Superusercomponents/Approvepc";
import Approvealle from "../../Components/Superusercomponents/Approvealle";
import Approveallpc from "../../Components/Superusercomponents/Approveallpc";
import Approvealldr from "../../Components/Superusercomponents/Approvealldr";
import Approveeallit from "../../Components/Superusercomponents/Approveallit";

export default function Billapprove() {
  const { user, id, type } = useParams();
  const navigate = useNavigate();
  const usertype = window.localStorage.getItem("usertype");
  useEffect(() => {
    if (usertype !== "Superuser") {
      alert("Access Restricted..!");
      navigate(`/dashboard/${user}`);
    }
  }, []);
  return (
    <Maincomponent tabname={"Other Receipt"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>APPROVE {type.toUpperCase()} BILL</b>
          </div>
          <br />
          <div className="Billcompmain">
            {/* <div className="Billcompmain1"></div> */}
            {type === "otherreceipt" ? <Approvedr /> : null}{" "}
            {type === "fundtransfer" ? <Approveit /> : null}
            {type === "expense" ? <Approvee /> : null}
            {type === "pettycash" ? <Approvepc /> : null}
            {/*  */}
            {type === "allotherreceipt" ? <Approvealldr /> : null}
            {type === "allexpense" ? <Approvealle /> : null}
            {type === "allpettycash" ? <Approveallpc /> : null}
            {type === "allfundtransfer" ? <Approveeallit /> : null}
          </div>
          <br />
          <br />
          <div className="Billcompmain"></div>
        </div>
      </div>
    </Maincomponent>
  );
}
