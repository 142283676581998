import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Apiurl from "../../Apiurl";

export default function Approveeallit() {
  const [jsonList, setJsonList] = useState([]);
  const apiurl = Apiurl();
  const { user, id, type } = useParams();
  const navigate = useNavigate();
  const usertype = window.localStorage.getItem("usertype");
  const [creditbname, setcreditbname] = useState(null);
  const [debitbname, setdebitbname] = useState(null);
  const [date, setdate] = useState("");
  const [amount, setamount] = useState("");
  const [billno, setbillno] = useState("");
  const [remarks, setremarks] = useState("");
  const [credit, setcredit] = useState(null);
  const [debit, setdebit] = useState(null);

  const [alldata, setalldata] = useState([]);

  const handleSubmit = async (fid) => {
    const formdata = {
      status: "Approved",
      isapproved: true,
    };
    try {
      const response = await axios.patch(
        apiurl + `fundtransfer/${fid}/`,
        formdata
      );
      alert("Bill Approved !");
      GetBillNoData();
    } catch (error) {
      console.error(error);
      alert("Some Error occured\nTry again Later");
    }
  };
  async function GetBillNoData() {
    try {
      const responseth = await axios.get(
        apiurl + `fundtransfer/?date=&status=Submitted&credit=&debit=`
      );
      console.log(responseth);
      setalldata(responseth.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    GetBillNoData();
  }, []);
  //

  return (
    <div
      style={{
        width: "100%",
        // backgroundColor: "red",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {alldata.length === 0 ? (
        <>All bills have been approved.</>
      ) : (
        <>
          {" "}
          {alldata.map((item, index) => (
            <div className="Billcompmain2" style={{ width: "40%" }}>
              {" "}
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Sno</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          readOnly
                          value={item.bill_no}
                          onChange={(e) => {
                            setbillno(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Date</div>
                      <div className="multisteptablecell2">
                        <Input
                          readOnly
                          type="date"
                          onChange={(e) => {
                            setdate(e.target.value);
                          }}
                          value={item.date}
                          className="textipbill"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Debit</div>
                      <div className="multisteptablecell2">
                        <Input
                          value={item.debit}
                          // onChange={handledebitChange}
                          // options={modeoptions}
                          // placeholder="Select Mode of payment"
                        />
                      </div>
                    </div>
                    {item.debit && item.debit == "Bank" ? (
                      <div className="multisteptablerow">
                        <div className="multisteptablecell1">Debit Bank</div>
                        <div className="multisteptablecell2">
                          <Input
                            value={item.debit_bank_name}
                            // onChange={(selectedOption) => {
                            //   setdebitbname(selectedOption);
                            // }}
                            // options={bankoptions}
                            // placeholder="Select Mode of payment"
                          />
                        </div>
                      </div>
                    ) : null}

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Credit</div>
                      <div className="multisteptablecell2">
                        <Input
                          value={item.credit}
                          // onChange={handlecreditChange}
                          // options={modeoptions2}
                          // placeholder="Select Mode of payment"
                        />
                      </div>
                    </div>

                    {item.credit && item.credit == "Bank" ? (
                      <div className="multisteptablerow">
                        <div className="multisteptablecell1">Credit Bank</div>
                        <div className="multisteptablecell2">
                          <Input
                            value={item.credit_bank_name}
                            // onChange={(selectedOption) => {
                            //   setcreditbname(selectedOption);
                            // }}
                            // options={bankoptions}
                            // placeholder="Select Mode of payment"
                          />
                        </div>
                      </div>
                    ) : null}

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Amount</div>
                      <div className="multisteptablecell2">
                        <Input
                          readOnly
                          type="text"
                          onChange={(e) => {
                            setamount(e.target.value);
                          }}
                          value={item.amount}
                          className="textipbill"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Description</div>
                      <div className="multisteptablecell2">
                        <Input
                          readOnly
                          type="text"
                          onChange={(e) => {
                            setremarks(e.target.value);
                          }}
                          value={item.remarks}
                          className="textipbill"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button
                  className="buttoncompnent1"
                  onClick={() => {
                    handleSubmit(item.id);
                  }}
                >
                  Approve
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
