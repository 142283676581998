import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
// import { Select } from "antd";
import { Space, Table, Tag } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";
import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Approvepc() {
  const [jsonList, setJsonList] = useState([]);
  //
  const apiurl = Apiurl();
  const { user, id } = useParams();
  const navigate = useNavigate();
  const [nameofpayer, setnameofpayer] = useState("");
  const usertype = window.localStorage.getItem("usertype");
  const [date, setdate] = useState("");
  const [amount, setamount] = useState("");
  const [billno, setbillno] = useState("");
  const [remarks, setremarks] = useState("");
  const [incomedata, setincomedata] = useState([]);
  const [totalcount, settotalcount] = useState(0);

  const handleSubmit = async () => {
    const formdata = {
      status: "Approved",
      isapproved: true,
    };
    try {
      const response = await axios.patch(apiurl + `pettycash/${id}/`, formdata);

      alert("Bill Approved");
      navigate(`/petty_cash/${user}`);
    } catch (error) {
      console.error(error);
      alert("Some Error occured\nTry again Later");
    }
  };
  async function GetBillNoData() {
    try {
      const responseth = await axios.get(apiurl + `pettycash/${id}/`);
      setbillno(responseth.data.bill_no);
      setdate(responseth.data.date);
      setamount(responseth.data.amount);
      setremarks(responseth.data.remarks);
      setnameofpayer(responseth.data.nameofpayer);
      settotalcount(responseth.data.length + 1);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    GetBillNoData();
  }, []);

  return (
    <div className="Billcompmain2">
      {" "}
      <div className="multistepcontainer1">
        <div className="multistepsubcontainer1">
          <div className="multisteptable">
            {/*  */}
            <div className="multisteptablerow">
              <div className="multisteptablecell1">Bill No</div>
              <div className="multisteptablecell2">
                <Input
                  type="text"
                  readOnly
                  value={billno}
                  onChange={(e) => {
                    setbillno(e.target.value);
                  }}
                  className="textipbill"
                />
              </div>
            </div>
            <div className="multisteptablerow">
              <div className="multisteptablecell1">Date</div>
              <div className="multisteptablecell2">
                <Input
                  type="date"
                  readOnly
                  onChange={(e) => {
                    setdate(e.target.value);
                  }}
                  value={date}
                  className="textipbill"
                />
              </div>
            </div>

            <div className="multisteptablerow">
              <div className="multisteptablecell1">Paid To</div>
              <div className="multisteptablecell2">
                <Input
                  type="text"
                  readOnly
                  value={nameofpayer}
                  onChange={(e) => {
                    setnameofpayer(e.target.value);
                  }}
                  className="textipbill"
                />
              </div>
            </div>

            <div className="multisteptablerow">
              <div className="multisteptablecell1">Amount</div>
              <div className="multisteptablecell2">
                <Input
                  type="text"
                  readOnly
                  onChange={(e) => {
                    setamount(e.target.value);
                  }}
                  value={amount}
                  className="textipbill"
                />
              </div>
            </div>

            <div className="multisteptablerow">
              <div className="multisteptablecell1">Details</div>
              <div className="multisteptablecell2">
                <Input
                  type="text"
                  readOnly
                  onChange={(e) => {
                    setremarks(e.target.value);
                  }}
                  value={remarks}
                  className="textipbill"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="multistepcontainerbtn">
        <button className="buttoncompnent1" onClick={handleSubmit}>
          Approve
        </button>
      </div>
    </div>
  );
}
