import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import Apiurl from "../../Apiurl";
import Bankoption from "../../Options/Bankoption";

export default function Approvealldr() {
  const apiurl = Apiurl();
  const bankoptions = Bankoption();
  const usertype = window.localStorage.getItem("usertype");
  // const options = Optionsdropdown();
  const { user, id, type } = useParams();
  const navigate = useNavigate();
  const [nameofpayer, setnameofpayer] = useState("");
  const [alldata, setalldata] = useState([]);

  const [date, setdate] = useState("");
  const [amount, setamount] = useState("");
  const [billno, setbillno] = useState("");
  const [remarks, setremarks] = useState("");
  const [mode, setmode] = useState(null);
  const [bname, setbname] = useState(null);

  const modeoptions = [
    { value: "Cash", label: "Cash" },
    { value: "Cheque", label: "Cheque" },
    { value: "Online", label: "Online" },
    { value: "Gpay/Phonepay/Paytm", label: "Gpay/Phonepay/Paytm" },
  ];

  const updateDate = () => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setdate(formattedDate);
  };

  const handleSubmit = async (id) => {
    const formdata = {
      status: "Approved",
      isapproved: true,
    };
    try {
      const response = await axios.patch(apiurl + `billing/${id}/`, formdata);
      alert("Bill Approved !");
      GetBillNoData();
    } catch (error) {
      console.error(error);
      alert("Some Error occured\nTry again Later");
    }
  };
  async function GetBillNoData() {
    try {
      const responseth = await axios.get(
        apiurl +
          `billing/?student_id=&std_name=&date=&status=Submitted&billtype=Income&billedby=`
      );
      setalldata(responseth.data);

      console.warn(responseth.data);
    } catch (error) {
      console.error(error);
    }
  }

  const handlemodeChange = (selectedOption) => {
    setmode(selectedOption);
    setbname(null);
  };
  useEffect(() => {
    updateDate();
    GetBillNoData();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        // backgroundColor: "red",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {alldata.length === 0 ? (
        <>All bills have been approved.</>
      ) : (
        <>
          {" "}
          {alldata.map((item, index) => (
            <div className="Billcompmain2" style={{ width: "40%" }}>
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Bill No</div>
                      <div className="multisteptablecell2">
                        <Input
                          readOnly
                          type="text"
                          value={item.bill_no}
                          onChange={(e) => {
                            setbillno(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Name</div>
                      <div className="multisteptablecell2">
                        <Input
                          readOnly
                          type="text"
                          value={item.nameofpayer}
                          onChange={(e) => {
                            setnameofpayer(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Date</div>
                      <div className="multisteptablecell2">
                        <Input
                          readOnly
                          type="date"
                          onChange={(e) => {
                            setdate(e.target.value);
                          }}
                          value={item.date}
                          // disabled
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Amount</div>
                      <div className="multisteptablecell2">
                        <Input
                          readOnly
                          type="text"
                          onChange={(e) => {
                            setamount(e.target.value);
                          }}
                          value={item.amount}
                          // disabled
                          className="textipbill"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Mode</div>
                      <div className="multisteptablecell2">
                        <Input
                          readOnly
                          value={item.mode}
                          // onChange={handlemodeChange}
                          // options={modeoptions}
                          placeholder="Select Mode of payment"
                        />
                      </div>
                    </div>
                    {item.mode && item.mode != "Cash" ? (
                      <div className="multisteptablerow">
                        <div className="multisteptablecell1">Bank</div>
                        <div className="multisteptablecell2">
                          <Input
                            readOnly
                            value={item.bank_name}
                            placeholder="Select Mode of payment"
                          />
                        </div>
                      </div>
                    ) : null}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Remarks</div>
                      <div className="multisteptablecell2">
                        <Input
                          readOnly
                          type="text"
                          onChange={(e) => {
                            setremarks(e.target.value);
                          }}
                          value={item.remarks}
                          className="textipbill"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button
                  className="buttoncompnent1"
                  onClick={() => {
                    handleSubmit(item.id);
                  }}
                >
                  Approve
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
