import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import Apiurl from "../../Apiurl";
import { Tabhelper } from "../../Helper/Tabhelper";
import Bankoption from "../../Options/Bankoption";
import { Button } from "react-bootstrap";

export default function Approvedr() {
  const apiurl = Apiurl();
  const bankoptions = Bankoption();
  const usertype = window.localStorage.getItem("usertype");
  // const options = Optionsdropdown();
  const { user, id, type } = useParams();
  const navigate = useNavigate();
  const [nameofpayer, setnameofpayer] = useState("");

  const [date, setdate] = useState("");
  const [amount, setamount] = useState("");
  const [billno, setbillno] = useState("");
  const [remarks, setremarks] = useState("");
  const [mode, setmode] = useState(null);
  const [bname, setbname] = useState(null);

  const [totalcount, settotalcount] = useState(0);
  const modeoptions = [
    { value: "Cash", label: "Cash" },
    { value: "Cheque", label: "Cheque" },
    { value: "Online", label: "Online" },
    { value: "Gpay/Phonepay/Paytm", label: "Gpay/Phonepay/Paytm" },
  ];
  let dollarIndianLocale = Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
  });

  const updateDate = () => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setdate(formattedDate);
  };

  const handleSubmit = async () => {
    const formdata = {
      status: "Approved",
      isapproved: true,
    };
    try {
      const response = await axios.patch(apiurl + `billing/${id}/`, formdata);
      alert("Bill Approved !");
      navigate(`/direct_receipt/${user}`);
    } catch (error) {
      console.error(error);
      alert("Some Error occured\nTry again Later");
    }
  };
  async function GetBillNoData() {
    try {
      const responseth = await axios.get(apiurl + `billing/${id}/`);
      setbillno(responseth.data.bill_no);
      setdate(responseth.data.date);
      setamount(responseth.data.amount);
      setnameofpayer(responseth.data.nameofpayer);
      setremarks(responseth.data.remarks);
      setmode({
        value: responseth.data.mode,
        label: responseth.data.mode,
      });
      console.warn(responseth.data);
    } catch (error) {
      console.error(error);
    }
  }

  const handlemodeChange = (selectedOption) => {
    setmode(selectedOption);
    setbname(null);
  };
  useEffect(() => {
    updateDate();
    GetBillNoData();
  }, []);

  return (
    <div className="Billcompmain2">
      <div className="multistepcontainer1">
        <div className="multistepsubcontainer1">
          <div className="multisteptable">
            {/*  */}
            <div className="multisteptablerow">
              <div className="multisteptablecell1">Bill No</div>
              <div className="multisteptablecell2">
                <Input
                  readOnly
                  type="text"
                  value={billno}
                  onChange={(e) => {
                    setbillno(e.target.value);
                  }}
                  className="textipbill"
                />
              </div>
            </div>

            <div className="multisteptablerow">
              <div className="multisteptablecell1">Name</div>
              <div className="multisteptablecell2">
                <Input
                  readOnly
                  type="text"
                  value={nameofpayer}
                  onChange={(e) => {
                    setnameofpayer(e.target.value);
                  }}
                  className="textipbill"
                />
              </div>
            </div>

            <div className="multisteptablerow">
              <div className="multisteptablecell1">Date</div>
              <div className="multisteptablecell2">
                <Input
                  readOnly
                  type="date"
                  onChange={(e) => {
                    setdate(e.target.value);
                  }}
                  value={date}
                  // disabled
                  className="textipbill"
                />
              </div>
            </div>

            <div className="multisteptablerow">
              <div className="multisteptablecell1">Amount</div>
              <div className="multisteptablecell2">
                <Input
                  readOnly
                  type="text"
                  onChange={(e) => {
                    setamount(e.target.value);
                  }}
                  value={amount}
                  // disabled
                  className="textipbill"
                />
              </div>
            </div>
            <div className="multisteptablerow">
              <div className="multisteptablecell1">Mode</div>
              <div className="multisteptablecell2">
                <Select
                  readOnly
                  value={mode}
                  onChange={handlemodeChange}
                  options={modeoptions}
                  placeholder="Select Mode of payment"
                />
              </div>
            </div>
            {mode && mode.value != "Cash" ? (
              <div className="multisteptablerow">
                <div className="multisteptablecell1">Bank</div>
                <div className="multisteptablecell2">
                  <Select
                    readOnly
                    value={bname}
                    onChange={(selectedOption) => {
                      setbname(selectedOption);
                    }}
                    options={bankoptions}
                    placeholder="Select Mode of payment"
                  />
                </div>
              </div>
            ) : null}
            <div className="multisteptablerow">
              <div className="multisteptablecell1">Remarks</div>
              <div className="multisteptablecell2">
                <Input
                  readOnly
                  type="text"
                  onChange={(e) => {
                    setremarks(e.target.value);
                  }}
                  value={remarks}
                  className="textipbill"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="multistepcontainerbtn">
        <button className="buttoncompnent1" onClick={handleSubmit}>
          Approve
        </button>
      </div>
    </div>
  );
}
