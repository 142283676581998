import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { useParams, useNavigate, resolvePath } from "react-router-dom";
import Select from "react-select";
import { Tag } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";
import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";
import Bankoption from "../../Options/Bankoption";

export default function Approvee() {
  const [jsonList, setJsonList] = useState([]);
  //
  const apiurl = Apiurl();
  const usertype = window.localStorage.getItem("usertype");
  const { user, id } = useParams();
  const [nameofpayer, setnameofpayer] = useState("");
  const [date, setdate] = useState("");
  const [amount, setamount] = useState("");
  const [billno, setbillno] = useState("");
  const [remarks, setremarks] = useState("None");
  const [mode, setmode] = useState(null);
  const [expensetypeval, setexpensetypeval] = useState(null);
  const [bname, setbname] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const formdata = {
      status: "Approved",
      isapprover: false,
    };
    // console.log(formdata);
    try {
      const response = await axios.patch(apiurl + `expense/${id}/`, formdata);
      alert(`Bill Approved !`);
      navigate(`/expense/${user}`);
    } catch (error) {
      console.error(error);
      alert("Some Error occured\nTry again Later");
    }
  };
  async function GetBillNoData() {
    try {
      const responseth = await axios.get(apiurl + `expense/${id}/`);
      setbillno(responseth.data.bill_no);
      setexpensetypeval(responseth.data.expensetype);
      setnameofpayer(responseth.data.nameofpayer);
      setdate(responseth.data.date);
      setamount(responseth.data.amount);
      setmode(responseth.data.mode);
      setbname(responseth.data.bank_name);
      setremarks(responseth.data.remarks);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    GetBillNoData();
  }, []);

  return (
    <div className="Billcompmain2">
      {" "}
      <div className="multistepcontainer1">
        <div className="multistepsubcontainer1">
          <div className="multisteptable">
            {/*  */}
            <div className="multisteptablerow">
              <div className="multisteptablecell1">Bill No</div>
              <div className="multisteptablecell2">
                <Input
                  type="text"
                  readOnly
                  value={billno}
                  onChange={(e) => {
                    setbillno(e.target.value);
                  }}
                  className="textipbill"
                />
              </div>
            </div>

            <div className="multisteptablerow">
              <div className="multisteptablecell1"> Type</div>
              <div className="multisteptablecell2">
                <Input
                  value={expensetypeval}
                  readOnly
                  // onChange={handleexpensetypechange}
                  // options={expensetype}
                  // placeholder="Select Mode of payment"
                />
              </div>
            </div>
            <div className="multisteptablerow">
              <div className="multisteptablecell1">Name</div>
              <div className="multisteptablecell2">
                <Input
                  type="text"
                  readOnly
                  value={nameofpayer}
                  onChange={(e) => {
                    setnameofpayer(e.target.value);
                  }}
                  className="textipbill"
                />
              </div>
            </div>

            <div className="multisteptablerow">
              <div className="multisteptablecell1">Date</div>
              <div className="multisteptablecell2">
                <Input
                  readOnly
                  type="date"
                  onChange={(e) => {
                    setdate(e.target.value);
                  }}
                  value={date}
                  className="textipbill"
                />
              </div>
            </div>

            <div className="multisteptablerow">
              <div className="multisteptablecell1">Amount</div>
              <div className="multisteptablecell2">
                <Input
                  readOnly
                  type="text"
                  onChange={(e) => {
                    setamount(e.target.value);
                  }}
                  value={amount}
                  className="textipbill"
                />
              </div>
            </div>
            <div className="multisteptablerow">
              <div className="multisteptablecell1">Mode</div>
              <div className="multisteptablecell2">
                <Input
                  readOnly
                  value={mode}
                  // onChange={handlemodeChange}
                  // options={modeoptions}
                  // placeholder="Select Mode of payment"
                />
              </div>
            </div>
            {mode && mode != "Cash" ? (
              <div className="multisteptablerow">
                <div className="multisteptablecell1">Bank</div>
                <div className="multisteptablecell2">
                  <Input
                    value={bname}
                    readOnly
                    // onChange={(selectedOption) => {
                    //   setbname(selectedOption);
                    // }}
                    // options={bankoptions}
                    // placeholder="Select Mode of payment"
                  />
                </div>
              </div>
            ) : null}

            <div className="multisteptablerow">
              <div className="multisteptablecell1">Description</div>
              <div className="multisteptablecell2">
                <Input
                  type="text"
                  readOnly
                  onChange={(e) => {
                    setremarks(e.target.value);
                  }}
                  value={remarks}
                  className="textipbill"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="multistepcontainerbtn">
        <button className="buttoncompnent1" onClick={handleSubmit}>
          Approve
        </button>
      </div>
    </div>
  );
}
