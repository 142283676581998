import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Apiurl from "../../Apiurl";

export default function Fundtransfer() {
  const [jsonList, setJsonList] = useState([]);
  const apiurl = Apiurl();
  const { user, id, type } = useParams();
  const navigate = useNavigate();
  const usertype = window.localStorage.getItem("usertype");
  const [creditbname, setcreditbname] = useState(null);
  const [debitbname, setdebitbname] = useState(null);
  const [date, setdate] = useState("");
  const [amount, setamount] = useState("");
  const [billno, setbillno] = useState("");
  const [remarks, setremarks] = useState("");
  const [credit, setcredit] = useState(null);
  const [debit, setdebit] = useState(null);
  const updateDate = () => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setdate(formattedDate);
  };
  const handleSubmit = async () => {
    const formdata = {
      status: "Approved",
      isapproved: true,
    };
    try {
      const response = await axios.patch(
        apiurl + `fundtransfer/${id}/`,
        formdata
      );
      alert("Bill Approved !");
      navigate(`/fund_transfer/${user}`);
    } catch (error) {
      console.error(error);
      alert("Some Error occured\nTry again Later");
    }
  };
  async function GetBillNoData() {
    try {
      const responseth = await axios.get(apiurl + `fundtransfer/${id}/`);
      console.log(responseth);
      setamount(responseth.data.amount);
      setcredit(responseth.data.credit);
      setdebit(responseth.data.debit);
      setremarks(responseth.data.remarks);
      setdate(responseth.data.date);
      setbillno(responseth.data.bill_no);
      setdebitbname(responseth.data.credit_bank_name);
      setcreditbname(responseth.data.debit_bank_name);
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    updateDate();
    GetBillNoData();
  }, []);
  //

  return (
    <div className="Billcompmain2">
      {" "}
      <div className="multistepcontainer1">
        <div className="multistepsubcontainer1">
          <div className="multisteptable">
            {/*  */}
            <div className="multisteptablerow">
              <div className="multisteptablecell1">Sno</div>
              <div className="multisteptablecell2">
                <Input
                  type="text"
                  readOnly
                  value={billno}
                  onChange={(e) => {
                    setbillno(e.target.value);
                  }}
                  className="textipbill"
                />
              </div>
            </div>

            <div className="multisteptablerow">
              <div className="multisteptablecell1">Date</div>
              <div className="multisteptablecell2">
                <Input
                  readOnly
                  type="date"
                  onChange={(e) => {
                    setdate(e.target.value);
                  }}
                  value={date}
                  className="textipbill"
                />
              </div>
            </div>
            <div className="multisteptablerow">
              <div className="multisteptablecell1">Debit</div>
              <div className="multisteptablecell2">
                <Input
                  value={debit}
                  // onChange={handledebitChange}
                  // options={modeoptions}
                  // placeholder="Select Mode of payment"
                />
              </div>
            </div>
            {debit && debit == "Bank" ? (
              <div className="multisteptablerow">
                <div className="multisteptablecell1">Debit Bank</div>
                <div className="multisteptablecell2">
                  <Input
                    value={debitbname}
                    // onChange={(selectedOption) => {
                    //   setdebitbname(selectedOption);
                    // }}
                    // options={bankoptions}
                    // placeholder="Select Mode of payment"
                  />
                </div>
              </div>
            ) : null}

            <div className="multisteptablerow">
              <div className="multisteptablecell1">Credit</div>
              <div className="multisteptablecell2">
                <Input
                  value={credit}
                  // onChange={handlecreditChange}
                  // options={modeoptions2}
                  // placeholder="Select Mode of payment"
                />
              </div>
            </div>

            {credit && credit == "Bank" ? (
              <div className="multisteptablerow">
                <div className="multisteptablecell1">Credit Bank</div>
                <div className="multisteptablecell2">
                  <Input
                    value={creditbname}
                    // onChange={(selectedOption) => {
                    //   setcreditbname(selectedOption);
                    // }}
                    // options={bankoptions}
                    // placeholder="Select Mode of payment"
                  />
                </div>
              </div>
            ) : null}

            <div className="multisteptablerow">
              <div className="multisteptablecell1">Amount</div>
              <div className="multisteptablecell2">
                <Input
                  readOnly
                  type="text"
                  onChange={(e) => {
                    setamount(e.target.value);
                  }}
                  value={amount}
                  className="textipbill"
                />
              </div>
            </div>
            <div className="multisteptablerow">
              <div className="multisteptablecell1">Description</div>
              <div className="multisteptablecell2">
                <Input
                  readOnly
                  type="text"
                  onChange={(e) => {
                    setremarks(e.target.value);
                  }}
                  value={remarks}
                  className="textipbill"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="multistepcontainerbtn">
        <button className="buttoncompnent1" onClick={handleSubmit}>
          Approve
        </button>
      </div>
    </div>
  );
}
