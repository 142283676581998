import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { useParams, useNavigate, resolvePath } from "react-router-dom";
import Select from "react-select";
import { Tag } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";
import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";
import Bankoption from "../../Options/Bankoption";

export default function Approvealle() {
  const [jsonList, setJsonList] = useState([]);
  //
  const apiurl = Apiurl();
  const usertype = window.localStorage.getItem("usertype");
  const { user, id } = useParams();

  const [nameofpayer, setnameofpayer] = useState("");
  const [date, setdate] = useState("");
  const [amount, setamount] = useState("");
  const [billno, setbillno] = useState("");
  const [remarks, setremarks] = useState("None");
  const [mode, setmode] = useState(null);
  const [expensetypeval, setexpensetypeval] = useState(null);
  const [bname, setbname] = useState(null);
  const [expdata, setexpdata] = useState([]);

  const navigate = useNavigate();

  const handleSubmit = async (eid) => {
    const formdata = {
      status: "Approved",
      isapprover: false,
    };
    // console.log(formdata);
    try {
      const response = await axios.patch(apiurl + `expense/${eid}/`, formdata);
      alert(`Bill Approved !`);
      GetBillNoData();
    } catch (error) {
      console.error(error);
      alert("Some Error occured\nTry again Later");
    }
  };
  async function GetBillNoData() {
    try {
      const responseth = await axios.get(
        apiurl + `expense/?date=&status=Submitted`
      );
      setexpdata(responseth.data);
      console.log(responseth.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    GetBillNoData();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        // backgroundColor: "red",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {expdata.length === 0 ? (
        <>All bills have been approved.</>
      ) : (
        <>
          {" "}
          {expdata.map((item, index) => (
            <div className="Billcompmain2" style={{ width: "40%" }}>
              {" "}
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Bill No</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          readOnly
                          value={item.bill_no}
                          onChange={(e) => {
                            setbillno(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1"> Type</div>
                      <div className="multisteptablecell2">
                        <Input
                          value={item.expensetype}
                          readOnly
                          // onChange={handleexpensetypechange}
                          // options={expensetype}
                          // placeholder="Select Mode of payment"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Name</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          readOnly
                          value={item.nameofpayer}
                          onChange={(e) => {
                            setnameofpayer(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Date</div>
                      <div className="multisteptablecell2">
                        <Input
                          readOnly
                          type="date"
                          onChange={(e) => {
                            setdate(e.target.value);
                          }}
                          value={item.date}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Amount</div>
                      <div className="multisteptablecell2">
                        <Input
                          readOnly
                          type="text"
                          onChange={(e) => {
                            setamount(e.target.value);
                          }}
                          value={item.amount}
                          className="textipbill"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Mode</div>
                      <div className="multisteptablecell2">
                        <Input
                          readOnly
                          value={item.mode}
                          // onChange={handlemodeChange}
                          // options={modeoptions}
                          // placeholder="Select Mode of payment"
                        />
                      </div>
                    </div>
                    {mode && mode != "Cash" ? (
                      <div className="multisteptablerow">
                        <div className="multisteptablecell1">Bank</div>
                        <div className="multisteptablecell2">
                          <Input
                            value={item.bname}
                            readOnly
                            // onChange={(selectedOption) => {
                            //   setbname(selectedOption);
                            // }}
                            // options={bankoptions}
                            // placeholder="Select Mode of payment"
                          />
                        </div>
                      </div>
                    ) : null}

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Description</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          readOnly
                          onChange={(e) => {
                            setremarks(e.target.value);
                          }}
                          value={item.remarks}
                          className="textipbill"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button
                  className="buttoncompnent1"
                  onClick={() => {
                    handleSubmit(item.id);
                  }}
                >
                  Approve
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
