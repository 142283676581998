import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
// import { Select } from "antd";
import { Space, Table, Tag } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";
import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Approveallpc() {
  const [jsonList, setJsonList] = useState([]);
  //
  const apiurl = Apiurl();
  const { user, id } = useParams();
  const navigate = useNavigate();
  const [nameofpayer, setnameofpayer] = useState("");
  const usertype = window.localStorage.getItem("usertype");
  const [date, setdate] = useState("");
  const [amount, setamount] = useState("");
  const [billno, setbillno] = useState("");
  const [remarks, setremarks] = useState("");
  const [incomedata, setincomedata] = useState([]);
  const [totalcount, settotalcount] = useState(0);
  const [alldata, setalldata] = useState([]);

  const handleSubmit = async (pid) => {
    const formdata = {
      status: "Approved",
      isapproved: true,
    };
    try {
      const response = await axios.patch(
        apiurl + `pettycash/${pid}/`,
        formdata
      );

      alert("Bill Approved");
      GetBillNoData();
    } catch (error) {
      console.error(error);
      alert("Some Error occured\nTry again Later");
    }
  };
  async function GetBillNoData() {
    try {
      const responseth = await axios.get(
        apiurl + `pettycash/?date=&status=Submitted`
      );
      setalldata(responseth.data);
      console.log(responseth.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    GetBillNoData();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        // backgroundColor: "red",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {alldata.length === 0 ? (
        <>All bills have been approved.</>
      ) : (
        <>
          {" "}
          {alldata.map((item, index) => (
            <div className="Billcompmain2" style={{ width: "40%" }}>
              {" "}
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Bill No</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          readOnly
                          value={item.bill_no}
                          onChange={(e) => {
                            setbillno(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Date</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="date"
                          readOnly
                          onChange={(e) => {
                            setdate(e.target.value);
                          }}
                          value={item.date}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Paid To</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          readOnly
                          value={item.nameofpayer}
                          onChange={(e) => {
                            setnameofpayer(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Amount</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          readOnly
                          onChange={(e) => {
                            setamount(e.target.value);
                          }}
                          value={item.amount}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Details</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          readOnly
                          onChange={(e) => {
                            setremarks(e.target.value);
                          }}
                          value={item.remarks}
                          className="textipbill"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button
                  className="buttoncompnent1"
                  onClick={() => {
                    handleSubmit(item.id);
                  }}
                >
                  Approve
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
